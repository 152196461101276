export function formatDuration(duration: number): string {
	let calculatedHr = 0;
	let calculatedMin = Math.round(duration / 60);
	let durationString = '';

	if (calculatedMin >= 60) {
		calculatedHr = Math.floor(calculatedMin / 60);
		durationString = `${calculatedHr}h `;
	}

	calculatedMin -= calculatedHr * 60;

	if (calculatedMin > 0) {
		durationString = durationString.concat(`${calculatedMin}m`);
	} else if (calculatedHr === 0) {
		durationString = '1m';
	}

	return durationString;
}

export function formatDurationISO8601(duration: number): string {
	const h = Math.floor(duration / 3600);
	const m = Math.floor((duration % 3600) / 60);
	const s = Math.floor(duration % 60);

	let durationString = 'PT';
	if (h) {
		durationString += `${h}H`;
	}
	if (m) {
		durationString += `${m}M`;
	}
	if (s) {
		durationString += `${s}S`;
	}

	return durationString;
}
