const config = {
	development: {
		faithlife: 'https://faithlife.com',
		sermons: 'https://sermons.faithlife.com',
		faithlifeTv: 'http://localhost:3100',
	},
	internal: {
		faithlife: 'https://test.faithlife.com',
		sermons: 'https://sermons.faithlife.com',
		faithlifeTv: 'https://internal.faithlifetv.com',
	},
	production: {
		faithlife: 'https://faithlife.com',
		sermons: 'https://sermons.faithlife.com',
		faithlifeTv: 'https://faithlifetv.com',
	},
	test: {
		faithlife: 'https://faithlife.com',
		sermons: 'https://sermons.faithlife.com',
		faithlifeTv: 'https://faithlifetv.com',
	},
};

const env =
	typeof window !== 'undefined' ? window.NODE_CONFIG_ENV : process.env.NODE_CONFIG_ENV || process.env.NODE_ENV;
const baseUrls =
	env === 'development'
		? config.development
		: env === 'internal'
		? config.internal
		: env === 'production'
		? config.production
		: config.test;

export default baseUrls;
