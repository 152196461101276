import React from 'react';
import Helmet from 'react-helmet';
import { amplitudeLogEvent } from '../../helpers/amplitude-helper';
import { connect } from '../../connect';
import { ContentManagement } from '../../components/content-management';
import { getContentManagementPageIfNecessary } from '../../actions';
import logger from '../../utils/logger';
import { IContentManagementState } from '../../reducers/content-management-reducers';
import { IReduxProps } from '../../actions/action-types';
import { LoadingIndicator } from '../../components/loading-indicator';
import { NotFound } from '../../components/not-found';

function mapStateToProps({ contentManagement, dispatch }) {
	return {
		contentManagement,
		dispatch,
	};
}

interface IContentManagementPageProps extends IReduxProps {
	location: URL;
	contentManagement: IContentManagementState;
}

@connect(mapStateToProps)
export default class ContentManagementPage extends React.Component<IContentManagementPageProps> {
	static activate(store, { location }, hasActivated) {
		return hasActivated
			? Promise.resolve()
			: store.dispatch(getContentManagementPageIfNecessary(location.pathname)).catch(logger.error);
	}

	componentDidMount() {
		const {
			contentManagement: { page, isFetching },
			dispatch,
			location,
		} = this.props;
		if (location.pathname && !isFetching && !page) {
			dispatch(getContentManagementPageIfNecessary(location.pathname)).catch(logger.error);
		}
		this.tryLogPageViewed();
	}

	componentDidUpdate(prevProps: IContentManagementPageProps) {
		const { contentManagement } = this.props;
		if (contentManagement.isFetching !== prevProps.contentManagement.isFetching) {
			this.tryLogPageViewed();
		}
	}

	private tryLogPageViewed() {
		const { contentManagement, location } = this.props;
		if (!contentManagement.isFetching && contentManagement.page) {
			amplitudeLogEvent('Content Management - Viewed', {
				Path: location.pathname,
			});
		}
	}

	render() {
		const { contentManagement, dispatch, location } = this.props;
		const { page, isFetching, isError } = contentManagement;
		const redirectLocation = !isFetching && page && page.shouldRedirect && page.redirectLocation;

		return isFetching ? (
			<LoadingIndicator />
		) : redirectLocation ? (
			<>
				<Helmet meta={[{ httpEquiv: 'refresh', content: `0;url=${redirectLocation}` }]} />
			</>
		) : page ? (
			<ContentManagement
				dispatch={dispatch}
				pagePath={location.pathname}
				isFetching={isFetching}
				isError={isError}
				page={page}
			/>
		) : (
			<>
				<Helmet title="Not Found" />
				<NotFound />
			</>
		);
	}
}
