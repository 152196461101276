import React from 'react';
import Helmet from 'react-helmet';
import { getContentManagementPageIfNecessary } from '../../actions';
import { ErrorPage } from '../../components/error-page';
import { createContentManagementOpenGraphMetaData } from '../../helpers/metadata-helper';
import logger from '../../utils/logger';
import { IContentManagementState, IPage } from '../../reducers/content-management-reducers';
import { IReduxProps } from '../../actions/action-types';

interface IContentManagementProps extends IContentManagementState, IReduxProps {
	pagePath: string;
}

export class ContentManagement extends React.Component<IContentManagementProps> {
	private element?: HTMLElement | null;

	componentDidMount() {
		const { dispatch, pagePath } = this.props;
		dispatch(getContentManagementPageIfNecessary(pagePath))
			.then(() => this.updateComponent())
			.catch(logger.error);
	}

	componentDidUpdate(prevProps: IContentManagementProps) {
		const { dispatch, pagePath } = this.props;
		if (pagePath !== prevProps.pagePath) {
			dispatch(getContentManagementPageIfNecessary(pagePath))
				.then(() => this.updateComponent())
				.catch(logger.error);
		}
	}

	updateComponent() {
		const { page } = this.props;
		if (!page) {
			return;
		}

		this.loadScripts(page);

		const { Main: mainSection } = page.sections;
		if (mainSection) {
			for (const contentItem of mainSection) {
				this.loadScripts(contentItem);
			}
		}
	}

	loadScripts(item: IPage) {
		if (!this.element) {
			return;
		}

		const element = this.element;

		const externalJS = (item.metadata && item.metadata.filter(x => x.key === 'js-external' && x.value)) || [];
		const inlineJS = (item.metadata && item.metadata.filter(x => x.key === 'js-inline' && x.value)) || [];

		let externalScriptLoadedCount = 0;
		const handleExternalScriptLoaded = () => {
			externalScriptLoadedCount++;
			if (externalScriptLoadedCount === externalJS.length) {
				loadInlineScripts();
			}
		};

		const loadInlineScripts = () => {
			// TODO: ensure the component is still mounted with the same props
			for (const { value } of inlineJS) {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.async = false;
				// eslint-disable-next-line no-unsanitized/property
				script.innerHTML = value;
				script.onload = ev => console.log('loaded', ev);
				element.appendChild(script);
			}
		};

		for (const { value } of externalJS) {
			const script = document.createElement('script');
			script.async = false;
			script.src = value;
			script.onload = handleExternalScriptLoaded;
			element.appendChild(script);
		}
	}

	render() {
		const { page, isFetching, isError } = this.props;

		if (!isFetching && isError) {
			return <ErrorPage />;
		}

		if (!page) {
			return null;
		}

		const { sections, metadata, title, path, shouldIndex } = page;
		const { Main: mainSection } = sections;
		const meta = createContentManagementOpenGraphMetaData({
			title,
			path,
			metadata,
		});
		const link: JSX.IntrinsicElements['link'][] = [];
		const canonicalUrl = metadata && metadata.find(x => x.key === 'canonical-url');
		if (canonicalUrl) {
			link.push({ rel: 'canonical', href: canonicalUrl.value });
		}

		if (!mainSection) {
			return null;
		}

		/* eslint-disable react/no-danger */
		return (
			<div ref={el => (this.element = el)}>
				<Helmet
					title={title}
					meta={!shouldIndex ? [...meta, { name: 'robots', content: 'noindex, nofollow' }] : meta}
					link={link}
				/>

				{mainSection.map((contentItem, i) => {
					const inlineCSS = (contentItem.metadata && contentItem.metadata.filter(x => x.key === 'css-inline')) || [];
					const externalCSS =
						(contentItem.metadata && contentItem.metadata.filter(x => x.key === 'css-external')) || [];
					return (
						<div key={i}>
							{externalCSS.map((x, j) => (
								<link key={`${x.key}:${j}`} rel="stylesheet" type="text/css" href={x.value} />
							))}
							{inlineCSS.map((x, j) => (
								<style key={`${x.key}:${j}`} type="text/css" dangerouslySetInnerHTML={{ __html: x.value }} />
							))}
							<div key={contentItem.id} dangerouslySetInnerHTML={{ __html: contentItem.content }} />
						</div>
					);
				})}
			</div>
		);
		/* eslint-enable react/no-danger */
	}
}
