import React from 'react';
import { Link } from 'react-router-dom';
import './index.less';

export class NotFound extends React.Component {
	render() {
		return (
			<div className="not-found">
				<div className="container">
					<h1>Oops!</h1>
					{/* TODO: Fix this the next time the file is edited. */}
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					<h3>We can't seem to find the page you are looking for</h3>
					<Link to="/">
						<button>Back to Homepage</button>
					</Link>
				</div>
			</div>
		);
	}
}
