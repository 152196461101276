import sortBy from 'lodash.sortby';
import { formatDurationISO8601 } from './duration-helper';
import { IItem } from '../reducers/item-reducers';
import baseUrls from '../data/baseUrls';

const twitterHandle = '@faithlife';
const defaultImage = 'https://covers.logoscdn.com/vod/covers/faithlife-tv-cover-landscape-hd-550x312.png';

interface IMetaData {
	content: string;
	property?: string;
	name?: string;
}

export const createContentManagementOpenGraphMetaData = ({ title, path, metadata = [] }: any) => {
	const description = metadata.find(data => data.key === 'description');
	const image = metadata.find(data => data.key === 'image');
	const meta: IMetaData[] = [
		{ property: 'og:title', content: title },
		{ property: 'og:type', content: 'website' },
		{ property: 'og:site_name', content: 'Faithlife TV' },
		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:site', content: twitterHandle },
		{
			property: 'og:url',
			content: `${baseUrls.faithlifeTv}${!path.startsWith('/') ? '/' : ''}${path}`,
		},
		...metadata.map(data => ({
			property: `og:${data.key}`,
			content: data.value,
		})),
	];

	if (description) {
		meta.push({ property: 'description', content: description.value });
	}

	if (image) {
		meta.push({ property: 'image', content: image.value });
	} else {
		meta.push({ property: 'image', content: defaultImage });
		meta.push({ property: 'og:image', content: defaultImage });
	}

	return meta;
};

export function createItemOpenGraphMetadata({
	item,
	itemId,
	playlistId = null,
	startTime,
}: {
	item?: IItem | null;
	itemId?: string;
	playlistId?: string | null;
	startTime?: number;
}) {
	if (!item && !itemId) {
		return [];
	}

	const covers = item && item.covers && sortBy(item.covers, x => x.width * x.height * -1);
	const cover = covers && covers.length && covers[0];
	const imageUrl = cover ? cover.url : defaultImage;
	const aspectRatio = (cover ? cover.width / cover.height : 16 / 9).toFixed(3).toString();

	const title = item ? item.title : 'Faithlife TV';

	const itemUrl = `${baseUrls.faithlifeTv}/items/${itemId}`;

	const mediaUrl =
		item && item.kind === 'series' && item.subItems && item.subItems.length
			? `${baseUrls.faithlifeTv}/media/${item.subItems[0].id}`
			: `${baseUrls.faithlifeTv}/media/${itemId}`;
	let queryParameters = playlistId ? `&playlistId=${playlistId}&resume=false` : '';
	if (startTime) {
		queryParameters += `&t=${startTime}`;
	}
	const faithlifeUrl = `${mediaUrl}?autoplay=1${queryParameters}`;

	const meta: IMetaData[] = [
		{ property: 'og:title', content: title },
		{ property: 'og:type', content: 'video.other' },
		{ property: 'og:url', content: itemUrl },
		{ property: 'faithlife:embed:uri', content: faithlifeUrl },
		{ property: 'faithlife:embed:aspectratio', content: aspectRatio },
		{ property: 'twitter:site', content: twitterHandle },
		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:title', content: title },
		{ property: 'twitter:image', content: imageUrl },
	];

	if (covers && covers.length) {
		for (const image of covers) {
			meta.push(
				{ property: 'og:image', content: image.url },
				{ property: 'og:image:width', content: image.width },
				{ property: 'og:image:height', content: image.height }
			);
		}
	} else {
		meta.push({ property: 'og:image', content: imageUrl });
	}

	if (item) {
		const description =
			item.description ||
			(item.parentItem ? item.parentItem.description : null) ||
			`Watch "${item.title}" on Faithlife TV, the premier video library for everyone—students, kids, families, and Bible enthusiasts.`;
		meta.push({ name: 'description', content: description });
		meta.push({ property: 'og:description', content: description });
		meta.push({ property: 'twitter:description', content: description });

		if (item.categories && !item.categories.length && !item.parentId) {
			meta.push({ name: 'robots', content: 'noindex' });
		}
	} else {
		meta.push({ name: 'robots', content: 'noindex' });
	}

	return meta;
}

export function createOpenGraphMetadata(imageUrl = defaultImage) {
	const title = 'Christian Movies, TV Shows, Documentaries & more | Faithlife TV';
	const description =
		'Enjoy thousands of hours of Christian movies, shows, and biblical teaching on Faithlife TV—Enjoy Faithlife Originals, free family entertainment, and community video streamed to your TV, computer, or mobile device for all-day inspiration.';
	const meta = [
		{ name: 'description', content: description },
		{ property: 'og:title', content: title },
		{ property: 'og:description', content: description },
		{ property: 'og:image', content: imageUrl },
		{ property: 'twitter:site', content: twitterHandle },
		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:title', content: title },
		{ property: 'twitter:description', content: description },
		{ property: 'twitter:image', content: imageUrl },
	];
	return meta;
}

export function createOembedLink(url) {
	return {
		rel: 'alternate',
		type: 'application/json+oembed',
		href: `${baseUrls.faithlifeTv}/api/oembed?url=${encodeURIComponent(url)}&format=json`,
	};
}

/** Creates meta data for google video schema */
export function createItemVideoMetaData({ item, itemId }: { item?: IItem; itemId?: string }) {
	if (!item) {
		return null;
	}
	const format = item && item.formats ? [...item.formats.filter(x => x.type.startsWith('video/'))].shift() : null;

	const publisher = {
		'@type': 'Organization',
		name: 'Faithlife TV',
		logo: {
			'@type': 'ImageObject',
			url: `${baseUrls.faithlifeTv}/public/images/video-logo.png`,
			width: 256,
			height: 60,
		},
	};

	const meta = {
		'@context': 'http://schema.org',
		'@type': 'VideoObject',
		name: item.title,
		description: item.description,
		thumbnailUrl: item.covers.map(cover => cover.url),
		uploadDate: item.createdDate,
		duration: item.duration ? formatDurationISO8601(item.duration) : undefined,
		publisher,
		contentUrl: format ? format.url : undefined,
		embedUrl: `${baseUrls.faithlifeTv}/media/${itemId}?embedded=1`,
	};

	return meta;
}
