import React from 'react';
import '../../components/not-found/index.less';

export class ErrorPage extends React.Component {
	render() {
		return (
			<div className="error-page">
				<div className="container">
					<h1>Oops!</h1>
					<h3>Looks like something went wrong. We&#39;re working on it!</h3>
				</div>
			</div>
		);
	}
}
